import React from "react";
import { ContactProps } from "_types/graphql/iContact";
import { PrismicRichText, QualityWarranty } from "presentation/components";
import { ContainerContact } from "./style";
import { FormContact } from "./partials/FormContact";
import { DataContact } from "./partials/DataContact";

export function Contact({ dataPage, sectionQualityWarranty }: ContactProps) {
  const { data } = dataPage;
  return (
    <>
      <ContainerContact>
        <PrismicRichText
          className="contact__title"
          field={data?.titulo_da_pagina?.richText}
        />
        <hr className="contact__line" />
        <PrismicRichText
          className="contact__description"
          field={data?.descricao_curta?.richText}
        />
        <FormContact />
        <DataContact data={dataPage?.data} />
      </ContainerContact>
      <QualityWarranty data={sectionQualityWarranty} />
    </>
  );
}
