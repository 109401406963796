import React from "react";
import { Divider, PrismicRichText } from "presentation/components";
import {
  AddressIcon,
  EmailIcon,
  PhoneIcon,
  WhatsAppIcon
} from "presentation/assets/icons";
import type { DataContactProps } from "_types/graphql/iContact";
import { ContainerDataContact } from "./style";

export function DataContact({ data }: DataContactProps): JSX.Element {
  function formatNumberLink(value: string) {
    return value
      .replace(/\s/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/\-/g, "");
  }
  return (
    <ContainerDataContact>
      <Divider title="Entre em contato" />
      <ul className="data__items">
        <li className="item__line">
          <a
            href={`https://wa.me/${
              formatNumberLink(data?.whatsapp?.text) ?? ""
            }`}
            target="_blank"
            className="item__link"
            rel="noreferrer"
          >
            <WhatsAppIcon className="item__social" />
            <span className="link__text">{data?.whatsapp?.text}</span>
          </a>
        </li>
        <li className="item__line">
          <a
            href={`tel: ${data?.telefone_1?.text}`}
            className="item__link"
            target="_blank"
            rel="noreferrer"
          >
            <PhoneIcon className="item__social" />
            <span className="link__text">{data?.telefone_1?.text}</span>
          </a>
        </li>
        <li className="item__line">
          <a
            href={`mailto: ${data?.email?.text}`}
            className="item__link"
            target="_blank"
            rel="noreferrer"
          >
            <EmailIcon className="item__social" />
            <span className="link__text">{data?.email?.text}</span>
          </a>
        </li>
        <li className="item__line">
          <a
            href={data?.endereco[0]?.link_mapa?.url}
            className="item__link item__link--adress"
            target="_blank"
            rel="noreferrer"
          >
            <AddressIcon className="item__social" />
            <PrismicRichText
              className="link__text"
              field={data?.endereco[0].endereco_completo.richText}
            />
          </a>
        </li>
      </ul>
    </ContainerDataContact>
  );
}
