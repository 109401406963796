import React from "react";
import { graphql } from "gatsby";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import type { PrismicContactProps } from "_types/graphql/iContact";
import { Contact } from "presentation/screens/Contact";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import {
  getDataFooter,
  getDataMenu,
  getDataSearchMenu,
  getDataSectionQualityWarranty
} from "_utils/assemblePagesData";
import { WEBSITE_URL } from "_utils/constants";
import SEO from "presentation/Seo";

export default function PaginaContato({ data }: PrismicContactProps) {
  const isExistSpecialties =
    data?.specialties?.nodes && data?.specialties?.nodes.length > 0;
  const specialties = isExistSpecialties
    ? data?.specialties.nodes.map(item => ({
        label: item?.data?.nome_da_especialidade.richText[0].text,
        icon: item?.data?.icone_da_especialidade?.url,
        url: item?.url
      }))
    : [];

  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });
  const qualityWarranty = getDataSectionQualityWarranty({
    sectionQuality: data?.sectionQuality
  });
  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });

  const footerData = getDataFooter({ data: data?.menu?.data?.body });

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.contact?.data?.canonical_url?.text}`,
    noFollow: data?.contact?.data?.no_follow,
    noIndex: data?.contact?.data?.no_index,
    openGraph: data?.contact?.data?.open_graph[0],
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    imagesPage: [],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.contact?.data?.open_graph[0]?.og_title?.text ?? "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.contact?.uid}`
      },
      {
        property: "og:image",
        content: data?.contact?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        footerData={footerData}
        contact={data?.contact}
      >
        <Contact
          dataPage={data?.contact}
          sectionQualityWarranty={qualityWarranty}
        />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query Contato {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }
    sectionQuality: prismicQualidade {
      ...QualityFragment
    }
    contact: prismicContato {
      uid
      data {
        no_follow
        no_index
        open_graph {
          og_url {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
          og_description {
            text
          }
        }
        canonical_url {
          text
        }
        whatsapp {
          text
        }
        telefone_1 {
          text
        }
        telefone_2 {
          text
        }
        email {
          text
        }
        endereco {
          endereco_completo {
            text
            richText
          }
          link_mapa {
            url
          }
        }
        titulo_da_pagina {
          richText
        }
        descricao_curta {
          richText
        }
      }
    }

    ctaContato: prismicCtaContato {
      ...CTAContatoFragment
    }
  }
`;
