import { pxToRem } from "_utils/pxToRem";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerSucessMessage = styled.div`
  ${LayoutGridSpace};
  width: 100%;
  margin-top: 48px;
  .message__title {
    color: ${colors.neutral[700]};
    line-height: ${pxToRem(32)};
    margin-bottom: 24px;
  }
  .message__description {
    color: ${colors.neutral[700]};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: 8px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
    .message__title {
      font-size: ${pxToRem(20.25)};
    }
    .message__title,
    .message__description {
      grid-column: 1 / span 2;
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    grid-template-columns: repeat(6, 1fr);
    .message__title {
      font-size: ${pxToRem(23.04)};
    }
    .message__title,
    .message__description {
      grid-column: 1 / span 6;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    grid-template-columns: repeat(8, 1fr);
    .message__title {
      font-size: ${pxToRem(23.04)};
    }
    .message__title,
    .message__description {
      grid-column: 1 / span 5;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(5, 1fr);
    margin-top: 48px;
    .message__title {
      font-size: ${pxToRem(25)};
    }
    .message__title,
    .message__description {
      grid-column: 1 / span 3;
    }
  }
`;
