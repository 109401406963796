import { pxToRem } from "_utils/pxToRem";
import { LayoutGrid } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerContact = styled.section`
  ${LayoutGrid};
  padding-top: 170px;
  > .contact__title {
    color: ${colors.neutral[900]};
  }
  .contact__line {
    background-color: ${colors.neutral[900]};
    margin-top: 16px;
    height: 1px;
    width: 100%;
    border: none;
  }
  > .contact__description {
    color: ${colors.neutral[900]};
    line-height: ${pxToRem(24)};
    margin-top: 32px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 80px;
    > .contact__title {
      grid-column: 1 / span 2;
      font-size: ${pxToRem(32.44)};
      line-height: ${pxToRem(32)};
    }
    .contact__line {
      grid-column: 1 / span 2;
      margin-bottom: 0;
    }
    > .contact__description {
      grid-column: 1 / span 2;
      font-size: ${pxToRem(18)};
      margin-top: 24px;
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    padding-bottom: 80px;
    > .contact__title {
      grid-column: 2 / span 6;
      font-size: ${pxToRem(47.78)};
      line-height: ${pxToRem(40)};
    }
    .contact__line {
      grid-column: 2 / span 6;
    }
    > .contact__description {
      grid-column: 2 / span 6;
      font-size: ${pxToRem(19.2)};
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    padding-bottom: 80px;
    > .contact__title {
      grid-column: 2 / span 7;
      font-size: ${pxToRem(47.78)};
      line-height: ${pxToRem(40)};
    }
    .contact__line {
      grid-column: 2 / span 7;
    }
    > .contact__description {
      grid-column: 2 / span 7;
      font-size: ${pxToRem(19.2)};
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    > .contact__title {
      grid-column: 2 / span 5;
      font-size: ${pxToRem(62)};
      line-height: ${pxToRem(56)};
    }
    .contact__line {
      grid-column: 2 / span 5;
    }
    > .contact__description {
      grid-column: 2 / span 5;
      font-size: ${pxToRem(20)};
    }
  }
`;
