import { pxToRem } from "_utils/pxToRem";
import { EffectHeightElement } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerDataContact = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  .data__items {
    margin: 8px 0 0;
    list-style: none;
    padding: 0;
    width: 100%;
    .item__line {
      width: 100%;
      display: flex;
      flex-direction: column;
      .item__link {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 16px 8px;
        border-bottom: 1px solid ${colors.neutral[900]};
        text-decoration: none;
        position: relative;
        :before {
          ${EffectHeightElement};
          background-color: #f0e4e1;
          top: auto;
          bottom: 0 !important;
          cursor: pointer;
        }
        :hover {
          font-weight: 600;
          :before {
            height: 100%;
          }
        }
        > .link__text {
          color: ${colors.neutral[900]};
          line-height: ${pxToRem(24)};
        }
        .item__social {
          display: flex;
          flex-shrink: 0;
          margin: 0;
        }
      }
      .item__link--adress {
        align-items: flex-start;
        > .link__text {
          word-break: normal !important;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    grid-column: 1 / span 2;
    .divider__line {
      height: 1px;
    }
    .data__items {
      margin-top: 4px;
      .item__line .item__link {
        padding: 14px 8px;
        > .link__text {
          font-size: ${pxToRem(18)};
          word-break: break-all;
        }
        .item__social {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}) and (max-width: 1919px) {
    grid-column: 2 / span 6;
    .data__items .item__line .item__link {
      padding: 16px;
      > .link__text {
        font-size: ${pxToRem(19.2)};
      }
    }
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    grid-column: 8 / span 4;
    .data__items .item__line .item__link {
      padding: 16px;
      > .link__text {
        font-size: ${pxToRem(20)};
      }
    }
  }
`;
