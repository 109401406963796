import styled from "styled-components";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";

export const ContainerFormContact = styled.form`
  margin-top: 80px;
  .form__fields {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 32px;
    position: relative;
    .field__input {
      width: 100%;
    }
    .field__hidden {
      position: absolute;
      height: 0;
    }
  }
  .form__control {
    ${LayoutGridSpace};
    margin-top: 48px !important;
    .control__button {
      width: 100%;
      :disabled {
        :hover {
          :before {
            height: 0;
          }
        }
      }
    }
    .control__button--sending {
      opacity: 1;
      background-color: ${colors.neutral[800]};
      .button__icon {
        background-color: ${colors.complementary[200]};
        svg path {
          fill: ${colors.neutral[900]};
        }
      }
      :hover {
        :before {
          height: 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    grid-column: 1 / span 2;
    .form__fields {
      margin-top: 40px;
      row-gap: 0px;
    }
    .form__control {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px !important;
      .control__button {
        grid-column: 1 / span 2;
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    grid-column: 2 / span 6;
    .form__control {
      grid-template-columns: repeat(6, 1fr);
      .control__button {
        grid-column: 4 / span 3;
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    grid-column: 2 / span 8;
    .form__fields {
      .field__input--small {
        width: calc(50% - 16px);
      }
    }
    .form__control {
      grid-template-columns: repeat(8, 1fr);
      .control__button {
        grid-column: 5 / span 4;
      }
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-column: 2 / span 5;
    .form__fields {
      .field__input--small {
        width: calc(50% - 16px);
      }
    }
    .form__control {
      grid-template-columns: repeat(5, 1fr);
      .control__button {
        grid-column: 4 / span 2;
      }
    }
  }
`;
