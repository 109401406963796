import React from "react";

export function useFormContact() {
  const [isSending, setIsSending] = React.useState(false);
  const [isShowSuccess, setIsShowSuccess] = React.useState(false);
  async function onSubmitContact({ values }: any) {
    try {
      setIsSending(true);
      const { subjectOptions, ...dataForm } = values;
      const payload = {
        ...dataForm,
        "form-name": "contato"
      };

      const getBody = Object.entries(payload)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              String(value ?? "sem valor")
            )}`
        )
        .join("&");

      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: getBody
      });
      setIsShowSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }
  return {
    onSubmitContact,
    isShowSuccess,
    isSending
  };
}
