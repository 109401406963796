import React from "react";
import { Form } from "react-final-form";
import { validateContact } from "_utils/form/validations";
import {
  Button,
  Divider,
  Input,
  InputTextArea,
  SelectOptions
} from "presentation/components";
import { maskPhone } from "_utils/regex/masks";
import { ContainerFormContact } from "./style";
import { useFormContact } from "./useFormContact";
import { SucessMessage } from "../SucessMessage";

export function FormContact(): JSX.Element {
  const { onSubmitContact, isShowSuccess, isSending } = useFormContact();
  return (
    <Form
      onSubmit={values => onSubmitContact({ values })}
      validate={validateContact}
      render={({ handleSubmit, form, valid }) => (
        <ContainerFormContact
          onSubmit={handleSubmit}
          data-netlify="true"
          name="contato"
          id="form-contato"
        >
          <Divider title="Envie uma mensagem" />
          {!isShowSuccess ? (
            <>
              <div className="form__fields">
                <input
                  type="hidden"
                  name="form-name"
                  value="contato"
                  className="field__hidden"
                />
                <Input type="hidden" name="subject" className="field__hidden" />
                <Input
                  type="hidden"
                  name="emailDestiny"
                  className="field__hidden"
                />
                <Input
                  className="field__input"
                  placeholder="Nome *"
                  name="name"
                  disabled={isSending}
                />
                <Input
                  className="field__input field__input--small"
                  placeholder="E-mail *"
                  name="email"
                  maxLength={200}
                  disabled={isSending}
                />
                <Input
                  className="field__input field__input--small"
                  placeholder="Telefone"
                  name="phone"
                  parse={maskPhone}
                  disabled={isSending}
                />
                <Input
                  className="field__input field__input--small"
                  placeholder="Cidade"
                  name="city"
                  maxLength={200}
                  disabled={isSending}
                />
                <Input
                  className="field__input field__input--small"
                  placeholder="Estado"
                  name="uf"
                  maxLength={200}
                  disabled={isSending}
                />
                <SelectOptions
                  name="subjectOptions"
                  placeholder="Assunto *"
                  disabled={isSending}
                  form={form}
                />
                <InputTextArea
                  className="field__input"
                  placeholder="Mensagem *"
                  name="message"
                  maxLength={500}
                  disabled={isSending}
                />
              </div>
              <div className="form__control">
                <Button
                  title={!isSending ? "Enviar Mensagem" : "Enviando..."}
                  className={`control__button ${
                    isSending ? "control__button--sending" : ""
                  }`}
                  iconName={!isSending ? "RightArrowIcon" : "UploadIcon"}
                  disabled={isSending || !valid}
                />
              </div>
            </>
          ) : (
            <SucessMessage />
          )}
        </ContainerFormContact>
      )}
    />
  );
}
