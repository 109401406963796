import React from "react";
import { ContainerSucessMessage } from "./style";

export function SucessMessage(): JSX.Element {
  return (
    <ContainerSucessMessage>
      <h2 className="message__title">Mensagem enviada com sucesso!</h2>
      <p className="message__description">
        Obrigado pelo seu contato. Nossa equipe já recebeu sua mensagem e assim
        que possível retornaremos se for necessário.
      </p>
      <p className="message__description">
        Caso precise De uma ajuda mais urgente, sugerimos entrar em contato
        pelos telefones informados nesta página.
      </p>
    </ContainerSucessMessage>
  );
}
